import React, { useRef } from 'react'
import { BusinessIntelligencePage, ConnectorPage, DestinationPage } from '../components'
 
const ConnectorPageTemplate = ({ pageContext, location }) => {
  const contentRef = useRef()

  if (pageContext.type === "bi") {
    return (
      <BusinessIntelligencePage 
        location={location} 
        pageContext={pageContext} 
        contentRef={contentRef}
      />
    )
  }

  if (pageContext.type === "destination") {
    return (
      <DestinationPage 
        location={location} 
        pageContext={pageContext} 
        contentRef={contentRef}
      />
    )
  }

  return (
    <ConnectorPage 
      location={location} 
      pageContext={pageContext} 
      contentRef={contentRef}
    />
  )
}

export default ConnectorPageTemplate
